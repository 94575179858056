
const theme = {
  muted: '#7E868C',
  primary: '#0074D9',
  tintPink: '#f7acb2',
  tintYellow: '#FFDFB1',
  tintGreen: '#e9ffe7',
  dark:'#3A3838'
}

export default theme