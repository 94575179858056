import React from 'react';
import styled from 'styled-components/macro';

const HeroImage = ({ src, alt, mb, mt }) => {
  return (
    <Box
      mt={mt}
      mb={mb}
    >
      <img src={src} alt={alt} />
    </Box>
  )
}

export default HeroImage;

const Box = styled.div`
  padding: 15px 0;
  border-radius: 3px;

  ${({ mt }) => mt &&`
    margin-top: ${mt}px;
  `}

  ${({ mb }) => mb &&`
    margin-bottom: ${mb}px;
  `}

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    border-radius: 4px;
  }
`;
