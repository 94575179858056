import axios from './axios';

export const sendGdprAcknowledgment = (url, frmData) => {
  return new Promise( async(resolve, reject) => {
    try {
      const response = await axios.post(url, frmData);
      // console.log('response', response);
      resolve(response.data);
    } catch(error) {
      // console.log(error.message);
      reject(error)
    }
  })
}