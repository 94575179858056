import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';

import axios from '../../services/api/axios';
import { Container, Content, Group, FormBox } from '../../components/containers/Container';
import Heading from '../../components/heading/Heading';
import SubHeading from '../../components/heading/SubHeading';
import Para from '../../components/para/Para';
import Progress from '../../components/progress/Progress';
import BlurOverlay from '../../components/block/BlurOverlay';
import Footer from '../../components/footer/Footer';

import { useIsMounted } from '../../hooks/useIsMounted';
import { generateParagraph } from '../../utils/generateParagraph';

const Application = () => {
  const {application, settings, status } = useSelector(state => state.root);
  const [screenState, setScreenState] = useState(null);
  const [uploadPercent, setUploadPercent] = useState(0);

  const sessionId = settings && settings.sessionId;
  const isMounted = useIsMounted();
  const history = useHistory();

  const headline = screenState && screenState.headline;
  const subheadline = screenState && screenState.subheadline;
  const introText = screenState && screenState.text;
  const formheadline = screenState && screenState.formheadline;
  const nextBtnText = screenState && screenState.btn.text;
  const isNextSkippable = screenState && screenState.skippable;

  const submitBtnText = screenState && screenState.submitBtn.text;
  const formFields = screenState && screenState.form;
  const requiredLabel = screenState && screenState.requiredLabel;
  const postUrl = screenState && screenState.post.url;

  const nextBtnPath = screenState && screenState.btn.path;
  const nextPath =  nextBtnPath ? nextBtnPath : '/'

  const submitBtnClass = isNextSkippable ? 'sa-btn sa-btn-block sa-btn-secondary' : 'sa-btn sa-btn-block';

  const fileInputNames = formFields && formFields.filter(item => item.type === 'file').map(item => item.name);
  const othersInputNames = formFields && formFields.filter(item => item.type !== 'file').map(item => item.name);
  const chooseFileState = fileInputNames && fileInputNames.reduce((acc, cur) => {
    return {...acc, [cur]: 'CHoose File'};
  }, {});
  const [chooseFile, setChooseFile] = useState(chooseFileState);
  
  // useForm 
  const { isDirty, isValid, register, handleSubmit, errors, } = useForm();

  const userId = window.localStorage.getItem("userId");

  // generate form from json/redux
  const renderFields = (fields) => {
    return fields.map((field, idx) => {
      return (
        <Group key={`${field.name}${idx}`}>
          {field.type === 'text' && (
            <div>
              {field.sectionTitle && (
                <SubHeading>{field.sectionTitle}</SubHeading>
              )}
              <label htmlFor={field.name}>
                <strong>{field.title}</strong> {field.required && (<small className="required-color">{`(${requiredLabel})`}</small>)}
              </label>
              <input
                ref={register({ required: field.required, maxLength: field.maxlength })}
                className="form-control"
                defaultValue={field.defaultValue}
                type={field.type}
                name={field.name}
                id={field.name}
              />
              {errors[field.name] && <span className="danger-alert-text">{field.errortext}</span>}
            </div>
          )}
  
          {field.type === 'email' && (
            <div>
              {field.sectionTitle && (
                <SubHeading>{field.sectionTitle}</SubHeading>
              )}
              <label htmlFor={field.name}>
                <strong>{field.title}</strong> {field.required && (<small className="required-color">{`(${requiredLabel})`}</small>)}
              </label>
              <input
                ref={register({ required: field.required, maxLength: field.maxlength })}
                className="form-control"
                type={field.type}
                name={field.name}
                defaultValue={field.defaultValue}
                id={field.name}
              />
              {errors[field.name] && <span className="danger-alert-text">{field.errortext}</span>}
            </div>
          )}
  
          {field.type === 'select' && (
            <div>
              {field.sectionTitle && (
                <SubHeading>{field.sectionTitle}</SubHeading>
              )}
              <label htmlFor={field.name}>
                <strong>{field.title}</strong> {field.required && (<small className="required-color">{`(${requiredLabel})`}</small>)}
              </label>
              <select
                ref={register({ required: field.required })}
                className="custom-select"
                name={field.name}
                id={field.name}
                defaultValue={field.defaultValue}
              >
                {field.option && field.option.map(({ name, value }) => {
                  return <option key={name} value={value}>{name}</option>
                })}
              </select>
              {errors[field.name] && <span className="danger-alert-text">{field.errortext}</span>}
            </div>
          )}
  
          {field.type === 'textarea' && (
            <div>
              {field.sectionTitle && (
                <SubHeading>{field.sectionTitle}</SubHeading>
              )}
              <label htmlFor={field.name}>
                <strong>{field.title}</strong> {field.required && (<small className="required-color">{`(${requiredLabel})`}</small>)}
              </label>
              <textarea
                ref={register({ required: field.required })}
                className="form-control"
                name={field.name}
                id={field.name}
                rows="3"
              >
              </textarea>
              {errors[field.name] && <span className="danger-alert-text">{field.errortext}</span>}
            </div>
          )}
  
          {field.type === 'checkbox' && (
            <>
              {field.sectionTitle && (
                <SubHeading>{field.sectionTitle}</SubHeading>
              )}
              <div className="form-check">
                <input
                  ref={register({ required: field.required })}
                  className="form-check-input"
                  type={field.type}
                  name={field.name}
                  id={field.name}
                  value={field.checkboxValue}
                />
                <label className="form-check-label" htmlFor={field.name}>{field.title}</label>
              </div>
              {errors[field.name] && <span className="danger-alert-text">{field.errortext}</span>}
            </>
          )}
  
          {field.type === 'radio' && (
            <>
              {field.sectionTitle && (
                <SubHeading>{field.sectionTitle}</SubHeading>
              )}
              <label>
                <strong>{field.title}</strong> {field.required && (<small className="required-color">{`(${requiredLabel})`}</small>)}
              </label>
                {field.radioArray && field.radioArray.map((item, index) => {
                  return (
                    <div key={`${item.name}${index}`} className="form-check">
                      <input
                        ref={register({ required: field.required })}
                        className="form-check-input"
                        type="radio"
                        name={item.name}
                        id={`${item.name}${index}`}
                        value={item.radioValue}
                      />
                      <label className="form-check-label" htmlFor={`${item.name}${index}`}>{item.text}</label>
                    </div>
                  )
                })}
                {errors[field.name] && <span className="danger-alert-text">{field.errortext}</span>}
              </>
            )}
  
            {field.type === 'file' && (
              <>
                {field.sectionTitle && (
                  <SubHeading>{field.sectionTitle}</SubHeading>
                )}
                <label htmlFor={field.name}>
                  <strong>{field.title}</strong> <small>{`(${field.mimetypes.join(', ')})`}</small> {field.required && (<small className="required-color">{`(${field.requiredLabel})`}</small>)}
                </label>
                <div className="custom-file">
                  <input
                    ref={register({ required: field.required })}
                    type={field.type}
                    className="custom-file-input"
                    name={field.name}
                    id={field.name}
                    accept={field.mimetypes.join(',')}
                    onChange={(e) => onChangeFileName(e)}
                  />
                  <label htmlFor={field.name} className="custom-file-label">{chooseFile ? chooseFile[field.name]: 'Choose Files'}</label>
                </div>
              </>
            )}
        </Group>
      )
    })
  }
  
  // onChange file inputs
  const onChangeFileName = (e) => {
    var fileName = e.target.files[0].name;
    var id = e.target.id;
    setChooseFile({...chooseFile, [id]: fileName});
  }

  // form submit
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('section', 'application');
    othersInputNames.map(item => formData.append(item, data[item]));
    fileInputNames.map(item => formData.append(item, data[item][0] || 'No file added'));

    // console.table([...formData]) 
    
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor( (loaded * 100) / total);

        if ( percent < 100) {
          setUploadPercent(percent);
        }
      }
    }

    axios.post(postUrl, formData, options).then(res => {
      if (res.status === 200) {
        setUploadPercent(0);
        history.push(nextPath);
      }
    })
  }

  useEffect(() => {
    if (status === 'success' && isMounted.current) {
      setScreenState(application);
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [isMounted, status, application]);

  return (
    <>
      <Container>
        <Content>
          <div style={{ height: 20 }} />
          {headline && (
            <Heading
              align="center"
              mb={20}
              >
              {headline}
            </Heading>
          )}
          {subheadline && (
            <SubHeading
              align="center"
              mb={20}
              >
              {subheadline}
            </SubHeading>
          )}
          {introText && (
            <Para mb={20}>
              {introText && generateParagraph(introText)}
            </Para>
          )}

          {formheadline && (<p style={{marginBottom: 15, fontSize: 15, color: 'var(--muted)' }}>{formheadline}</p>)}
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormBox>
              {formFields && renderFields(formFields)}
              <Group className="mb-0">
                <button
                  type="submit"
                  disabled={isDirty || isValid}
                  className={submitBtnClass}
                >
                  {submitBtnText}
                </button>
              </Group>
            
            </FormBox>
          </form>
          <div style={{ height: 30 }} />
          {isNextSkippable && (
            <div style={{ paddingBottom: 30}}>
              <button type="button" className="sa-btn sa-btn-block">{nextBtnText}</button>
            </div>
          )}
          {uploadPercent > 0 && (
            <BlurOverlay>
              <Progress value={uploadPercent} />
            </BlurOverlay>
          )}
          <Footer sectionName="application" />
        </Content>
      </Container>
    </>
  )
}

export default Application;