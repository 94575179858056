import { createSlice } from '@reduxjs/toolkit';

const testSlice = createSlice({
  name: 'recording',
  initialState: {
    isSuccess: false,
    videoUrl: '',
    videoPoster: null, 
    section: 'functionTest',
    videoType: 'video/mp4',
    error: '',
  },
  reducers: {
    recordingSuccess: (state, { payload } ) => {
      state.isSuccess = true;
      state.videoUrl = payload.videoFile;
      state.videoType = payload.videoType;
      state.section = payload.section;
      state.videoPoster = payload.videoPoster;
    },
    recordingFail: (state, { pyaload }) => {
      state.videoUrl = '';
      state.error = pyaload;
    },
  }
});

export const { recordingSuccess, recordingFail } = testSlice.actions;

export default testSlice.reducer;
