import React, { useEffect } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

// screens
import Navbar from './components/navbar/Navbar';
import Welcome from './screens/welcome/Welcome';
import Home from './screens/home/Home';
import Gdpr from './screens/gdpr/Gdpr';
import Application from './screens/application/Application';
import Explanation from './screens/explanation/Explanation';
import CameraActivation from './screens/cameraactivation/CameraActivation';
import End from './screens/end/End';
import PersonalInfo from './screens/personalinfo/PersonalInfo';
import JobInfo from './screens/jobInfo/JobInfo';
import Questions from './screens/questions/Questions';
import FunctionTest from './screens/functiontest/FunctionTest';
import MediaRecordingTest from './screens/mediarecordingtest/MediaRecordingTest';
import SampleQuestion from './screens/samplequestion/SampleQuestion';
import RecordingSuccess from './screens/recordingsuccess/RecordingSuccess';
import Steps from './screens/steps/Steps';
import StepSuccess from './screens/stepsuccess/StepSuccess';
import NotFound from './screens/notfound/NotFound';
import VideoError from './screens/videoerror/VideoError';
import Alert from './alert/Alert';

import { loadAppData } from './services/slices/rootSlice';
// import { checkAndroid11 } from './hooks/checkAndroid11';

function App() {
  const dispatch = useDispatch();
  // const isAndroid11 = checkAndroid11();

  const { mediaAlert, videoInterview, settings, lang } = useSelector(state => state.root);
  const sessionId = settings && settings.sessionId;
  const defaultLang = settings && settings.languages.default;
  const steps = videoInterview && videoInterview.steps;
  const mediaPostUrl = videoInterview && videoInterview.post.mediaPostUrl;
  const backBTNText = videoInterview && videoInterview.backBTNText;

  const info = mediaAlert && mediaAlert.info;
  const notSupported = mediaAlert && mediaAlert.notSupported;
  const notAllowed = mediaAlert && mediaAlert.notAllowed;
  const combined = Object.assign({info, notSupported, notAllowed});
  window.localStorage.setItem('mediaAlert', JSON.stringify(combined));

  const stepsArr = steps && steps.map((item, index) => {
    const isLastElem = steps.length - 1 === index ? false : true;
    return  {
      ...item, 
      id: index, 
      path: `steps${index}`, 
      lastItem: isLastElem,
      backBTNText,
      mediaPostUrl
    }
  });

  const objQueryString = queryString.parse(window.location.search);
  const clearCache = objQueryString.clearCache;
  const codec = objQueryString.c;
  window.localStorage.setItem("codec", codec);

  const getSessionId = (objQueryString) => {
    let sessionId = objQueryString.sessId;
    if (sessionId === undefined) {
      sessionId = getSessionIdFromLocalStorage()
    }
    return sessionId;
  }

  const getSessionIdFromLocalStorage = () => {
    return window.localStorage.getItem("sessionId");
  }

  const setSessionIdFromLocalStorage = (sessionId) => {
    return window.localStorage.setItem("sessionId", sessionId);
  }

  const urlSessionId = getSessionId(objQueryString);

  
  useEffect(() => {
    let langParameter;
    if (lang === '') {
      langParameter = defaultLang;
    } else {
      langParameter = lang;
    }
    dispatch(loadAppData(`${urlSessionId}&lang=${langParameter}`));
  }, [dispatch, urlSessionId, defaultLang, lang]);


  // useEffect(() => {
  //   if (isAndroid11) {
  //     window.localStorage.setItem("isAndroid11", true);
  //   } else {
  //     window.localStorage.setItem("isAndroid11", false);
  //   }
  // }, [isAndroid11]);

  // useEffect(() => {
  //   window.localStorage.setItem("codec", codec);
  // }, [codec]);

  useEffect(() => {
    if (sessionId) {
      setSessionIdFromLocalStorage(sessionId);
      if (!window.localStorage.getItem("userId")) {
        window.localStorage.setItem("userId", nanoid(15));
      }
    }else {
      window.localStorage.removeItem("sessionId");
    }
  }, [sessionId]);

  useEffect(() => {
    if (clearCache) {
      window.localStorage.clear();
    }
  }, [clearCache]);

  return (
    <>
      <BrowserRouter>
        <Navbar />
        <main>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/welcome" component={Welcome} />
            <Route path="/jobinfo" component={JobInfo} />
            <Route path="/gdpr" component={Gdpr} />
            <Route path="/application" component={Application} />
            <Route path="/function-test" component={FunctionTest} />
            <Route path="/media-recording-test" component={MediaRecordingTest} />
            <Route path="/explanation" component={Explanation} />
            <Route path="/camera-activation" component={CameraActivation} />
            <Route path="/sample-question" component={SampleQuestion} />
            <Route path="/personalinfo" component={PersonalInfo} />
            <Route path="/questions" component={Questions} />
            <Route path="/end" component={End} />
            <Route path="/recording-success" component={RecordingSuccess} />
            <Route path="/step-success" component={StepSuccess} />
            {stepsArr && stepsArr.map((item, index) => (
              <Route key={index} path={`/${item.path}`} component={() => <Steps item={item} key={index} />} />
            ))}
            <Route path="/video-error" component={VideoError} />
            <Route path="/not-found" component={NotFound} />
          </Switch>
        </main>
        <Alert />
      </BrowserRouter>
    </>
  );
}

export default App;
