import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Link } from "react-router-dom";

import { Container, Content } from '../../components/containers/Container';
import Heading from '../../components/heading/Heading';
import SubHeading from '../../components/heading/SubHeading';
import Para from '../../components/para/Para';
import BlockSpinner from '../../components/spinner/BlockSpinner';
import Footer from '../../components/footer/Footer';

import { useIsMounted } from '../../hooks/useIsMounted';
import { generateParagraph } from '../../utils/generateParagraph';


const StepSuccess = () => {
  // hooks
  const isMounted = useIsMounted();
  const location = useLocation();
  
  const { videoInterview, status } = useSelector(state => state.root);
  const videoTest = useSelector(state => state.videoTest);
  const [screenState, setScreenState] = useState(null);
 
  const headline = screenState && screenState.finished.headline;
  const subheadline = screenState && screenState.finished.subheadline;
  const introText = screenState && screenState.finished.text;
  const nextBtnText = screenState && screenState.finished.nextBtn.text;
  const againBtnText = screenState && screenState.finished.againBtn.text;

  const videoUrl = videoTest && videoTest?.videoUrl;
  const videoType = videoTest && videoTest?.videoType;
  const playerOrientation = videoTest && videoTest.videoPoster?.orientation;
  const videoPoster = videoTest && videoTest.videoPoster?.src;
  const nextPath =  location.state ? location.state.nextPath : '/'
  const againPath =  location.state ? location.state.currentPath : '/'

  const embedClass = playerOrientation === 'landscape' ? 'embed-responsive-4by3' : 'embed-3by4';

  useEffect(() => {
    if (status === 'success' && isMounted.current) {
      setScreenState(videoInterview && videoInterview);
    }
  }, [isMounted, status, videoInterview]);

  return (
    <>
      <Container>
        <Content heightCalc>
          {
            status === 'loading' ?
            <BlockSpinner /> : 
            <section className="screen__recording-result">

              <Heading
                align="center"
                mb={25}
              >
                {headline}
              </Heading>

              {videoUrl && (
                <div className={`embed-responsive ${embedClass}`} style={{ marginBottom: 30 }}>
                  <video controls src={videoUrl} poster={videoPoster}>
                    <source src={videoUrl} type={videoType} />
                  </video>
                </div> 
              )}

              <SubHeading align="center" mb={20}>{subheadline}</SubHeading>

              <Para mb={30}>{introText && generateParagraph(introText)}</Para>

              <div style={{ paddingBottom: 30 }}>
                <Link to={nextPath} className="sa-btn sa-btn-block">{nextBtnText}</Link>
              </div>
              
              <div style={{ paddingBottom: 30 }}>
                <Link to={againPath} className="sa-btn sa-btn-block sa-btn-secondary">{againBtnText}</Link>
              </div>
              
              <Footer sectionName="step-success" />
            </section>
          }
        </Content>
      </Container>
    </>
  )
}

export default StepSuccess