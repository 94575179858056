import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { Container } from '../../components/containers/Container';

import { useIsMounted } from '../../hooks/useIsMounted';

const NotFound = () => {
  const {notFound, status } = useSelector(state => state.root);
  const [screenState, setScreenState] = useState(null);

  const headline = screenState && screenState.headline;
  const text =  screenState && screenState.text;
  const logoUrl =  screenState && screenState.staffapply.logo.src;

  const isMounted = useIsMounted();

  useEffect(() => {
    if (status === 'success' && isMounted.current) {
      setScreenState(notFound);
    }
  }, [isMounted, status, notFound]);

  return (
    <Container>
      <Box>
        <Logo>
          <img src={logoUrl} alt="staff apply logo" />
        </Logo>
        <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.375 21.75v0c-5.178 0-9.375-4.197-9.375-9.375s4.197-9.375 9.375-9.375c5.178 0 9.375 4.197 9.375 9.375s-4.197 9.375-9.375 9.375zM12.375 21c4.763 0 8.625-3.862 8.625-8.625s-3.862-8.625-8.625-8.625c-4.763 0-8.625 3.862-8.625 8.625s3.862 8.625 8.625 8.625v0zM9 11.25c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0zM15.75 11.25c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0zM12.361 15.75c-2.611 0-4.111 1.5-4.111 1.5v-0.75c0 0 1.5-1.5 4.111-1.5s4.139 1.5 4.139 1.5v0.75c0 0-1.528-1.5-4.139-1.5v0z"></path>
        </svg>
        <h4>{headline}</h4>
        <p>{text}</p>
      </Box>
    </Container>
   
  )
}

export default NotFound;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFC978;
  color: white;
  min-height: calc(100vh - 60px);

  svg {
    fill: currentColor;
    margin-bottom: 15px;
  }

  h4 {
    margin: 0;
    text-align: center;
  }

  p {
    padding: 15px;
    text-align: center;
    font-size: 17px;
  }
`;


const Logo = styled.div`
  padding: 20px 50px;
  text-align: center;

  img {
    max-width: 100%;
    height: auto;
  }
`;



