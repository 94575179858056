import { useState, useEffect } from 'react';

const DeceviceList = () => {
  const [audioValue, setAudioValue] = useState();
  const [videoValue, setVideoValue] = useState();
  const [videoOptions, setVideoOptions] = useState([]);
  const [audioOptions, setAudioOptions] = useState([]);
  const [mediaStream, setMediaStream] = useState(null);

  useEffect(() => {
    async function enableStream() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        setMediaStream(stream);

        let devices = await navigator.mediaDevices.enumerateDevices(); 
        setVideoValue(devices.filter(({ kind }) => kind === "videoinput")[0].deviceId);
        setAudioValue(devices.filter(({ kind }) => kind === "audioinput")[0].deviceId);
        setVideoOptions(devices.filter(item => item.kind === 'videoinput').map(({ deviceId, label }) => {
          return [{deviceId, label}]
        }));
        setAudioOptions(devices.filter(item => item.kind === 'audioinput').map(({ deviceId, label }) => {
          return [{deviceId, label}]
        }));  

      } catch(err) {
        console.log(err)
      }
    }

    // (async () => { 
    //   const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    //   let devices = await navigator.mediaDevices.enumerateDevices(); 
    //   setVideoValue(devices.filter(({ kind }) => kind === "videoinput")[0].deviceId);
    //   setAudioValue(devices.filter(({ kind }) => kind === "audioinput")[0].deviceId);
    //   setVideoOptions(devices.filter(item => item.kind === 'videoinput').map(({ deviceId, label }) => {
    //     return [{deviceId, label}]
    //   }));
    //   setAudioOptions(devices.filter(item => item.kind === 'audioinput').map(({ deviceId, label }) => {
    //     return [{deviceId, label}]
    //   }));  
    //   console.log('DL', stream);
    //   setMediaStream(stream);
    // })();

    if (!mediaStream) {
      enableStream();
      
    } else {
      return function cleanup() {
        mediaStream.getTracks().forEach(track => {
          mediaStream.removeTrack(track);
          track.stop();
        });
      }
    }
  }, [mediaStream]);

  // useLayoutEffect(() => {
  //   return () => {
  //     if (window.stream) {
  //       window.stream.getTracks().forEach(track => {
  //         window.stream.removeTrack(track);
  //         track.stop();
  //       });
  //     }
  //   }
  // }, []);

  return { audioValue, videoValue, videoOptions, audioOptions, setAudioValue, setVideoValue };
};


export default DeceviceList;