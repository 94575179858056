import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { nanoid } from '@reduxjs/toolkit';
import styled from 'styled-components/macro';
import { useSelector, useDispatch} from 'react-redux';
import { useForm } from 'react-hook-form';
import { isIOS } from 'react-device-detect';

import axios from '../../services/api/axios';
import {
  Container,
  Content,
  CenterMobile,
  CMElm
} from '../../components/containers/Container';
import Heading from '../../components/heading/Heading';
import SubHeading from '../../components/heading/SubHeading';
import Para from '../../components/para/Para';
import VideoIframe from '../../components/block/VideoIframe';
import HeroImage from '../../components/block/HeroImage';
import BlockSpinner from '../../components/spinner/BlockSpinner';
import ProgressBox from '../../components/progress/Progress';
import WistiaEmbed from '../../components/WistiaEmbed';
import Progress from '../../components/progress/Progress';
import Footer from '../../components/footer/Footer';
import { recordingSuccess, recordingFail } from '../../services/slices/testSlice';

import GetStream from '../../utils/GetStream';
import { useIsMounted } from '../../hooks/useIsMounted';
import { generateParagraph } from '../../utils/generateParagraph';

var MediaStreamRecorder = require('msr');

const Steps = ({ item }) => {
  // hooks
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const history = useHistory();

  const {status, videoInterview, settings } = useSelector(state => state.root);
  const sessionId = settings && settings.sessionId;
  const [uploadPercent, setUploadPercent] = useState(0);
 
  const {
    btn,
    headline,
    heroImage,
    heroPriority,
    lastItem,
    id,
    videId,
    subheadline,
    text,
    video,
    maxAnswerTime,
    toggleBtnText,
    recordBtnText,
    recordAgainBtnText,
    nextStepBtnText,
    stopRecordBtnText,
    recordFinishedText,
    remainingText,
    backBTNText,
    mediaPostUrl
  } = item;

  const heroVideo = video.src;
  const nextBtnText = btn.text
  const postUrl = videoInterview && videoInterview.post.url;
  const currentPath =  `steps${id}`;
  const nextPath =  lastItem ? `steps${id+1}` : btn.path;
  const disabledClass =  uploadPercent > 0 ? 'disabled' : '';

  // const deviceStr = isIOS ? 'IOS' : 'android 11';
  // const iosOrAndroid11 =  (isIOS || isAndroid11);
  const videoPlayer = video?.player;
  const userId = window.localStorage.getItem("userId");

  // useForm 
  const { register, handleSubmit,} = useForm();

  const startRecord = () => {
    document.getElementById('file').click();
  }
  
  // onChange file inputs
  const onChangeFileName = (e) => {
   document.getElementById('submit').click();
  }

  // form submit
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('section', `steps${id}`);
    formData.append('sessionId', sessionId);
    formData.append('userId', userId);
    formData.append('videId', videId);
    // formData.append('device', deviceStr);
    formData.append('video', data.userfile[0]);

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor( (loaded * 100) / total);

        if ( percent < 100) {
          setUploadPercent(percent);
        }
      }
    }

    axios.post(postUrl, formData, options).then(res => {
      console.log('res :', res)
      if (res.status === 200) {
        setUploadPercent(0);
        dispatch(
          recordingSuccess({
            videoFile: res.data.videofile,
            videoType: res.data.type,
            videoPoster: res.data.videoPoster,
            section: res.data.section
          })
        );
        history.push({
          pathname: '/step-success',
          state: { currentPath: currentPath, nextPath: nextPath }
        });
      } else {
        dispatch(recordingFail(res.error));
        window.location = `https://staffapply.com/video-error/?sid=${sessionId}&uid=${userId}`
      }
    })
  }

  let showHero;

  if (heroPriority === 'video') {
    if (videoPlayer === 'wistia') {
      showHero = (<div style={{ marginBottom: 25 }}><WistiaEmbed hashedId={heroVideo} /></div>)
    } else if (videoPlayer === 'Bunny') {
      showHero = (<VideoIframe title="welcome" src={heroVideo} mb={25} />)
    } else {
      showHero = (<VideoIframe title="welcome" src={heroVideo} mb={25} />)
    }
  }

  if (heroPriority === 'image') {
    showHero = (<HeroImage src={heroImage} mb={10} alt="welcome hero"/>)
  }

  // it then desktop or android
  const JSONconstraints = JSON.parse(window.localStorage.getItem("constraints"));
  const codec = window.localStorage.getItem("codec");

  const notIOS = !isIOS;
  const videoRef = useRef(null);
  const [hideTop, setHideTop] = useState(true);
  const [recordPreseed, setRecordPreseed] = useState(false);
  const [recordDone, setRecordDone] = useState(false);
  const [intervalID, setIntervalID] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [uploadState, setUploadState] = useState(false);

  const [count, setCount] = useState(0);
  const [resCount, setResCount] = useState(0);
  const [recordingId, setRecordingId] = useState('');
  const [sendOnce, setSendOnce] = useState(false);
  const [vcodec, setVcodec] = useState('video/webm;codecs="vp8,opus"');

  const nextDisabled = uploadState ? '' : 'disabled';

  const formatTime = val => `0${Math.floor(val)}`.slice(-2);

  function displayMinutes (seconds) {
    const minutes = (seconds % 3600) / 60;
    return formatTime(minutes);
  }

  function displaySeconds (seconds) {
    return formatTime(seconds % 60);
  }

  const [timerSeconds, setTimerSeconds] = useState(displaySeconds(maxAnswerTime));
  const [timerMinutes, setTimerMinutes] = useState(displayMinutes(maxAnswerTime));
 
  const RecordTimeMiliSeconds =   maxAnswerTime * 1000;

  function captureUserMedia(cnstrnt, successCallback, errorCallback) {
    navigator.mediaDevices.getUserMedia(cnstrnt).then(successCallback).catch(errorCallback);
  }

  const startRecording = async () => {
    if (JSONconstraints) {
      captureUserMedia(JSONconstraints, onMediaSuccess, onMediaError);
    } else {
      captureUserMedia({ video: true, audio: true }, onMediaSuccess, onMediaError);
    }

    setRecordPreseed(true);
   
    var countDown = new Date();
    countDown.setSeconds(countDown.getSeconds() + maxAnswerTime);

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDown - now;

      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if ( distance < 100) {
        clearInterval(interval);
        // setProgressV(100)
      } else {
        setTimerMinutes(formatTime(minutes))
        setTimerSeconds(formatTime(seconds));
        // setTimerSeconds(formatTime(seconds + 1));
        // setProgressV((prev) => {
        //   if ( prev > 0) {
        //     return prev - splitSlot;
        //   }
        //   clearInterval(interval);
        //   return prev
        // })
      }
    }, 1000);
    setIntervalID(interval)

    const timeOut = setTimeout(() => {
      stopRecording();
      setRecordDone(true);
      clearTimeout(timeOut);
    }, RecordTimeMiliSeconds)
    setTimeoutId(timeOut)
  }

  const stopRecording = () => {
    Steps.mediaRecorder.stop();
    
    Steps.localStream.getTracks().forEach(track => {
      Steps.localStream.removeTrack(track);
      track.stop();
    });
  }

  const manualStop = () => {
    clearInterval(intervalID);
    clearTimeout(timeoutId)
    setRecordDone(true);
    setTimerMinutes(displayMinutes(maxAnswerTime))
    setTimerSeconds(displaySeconds(maxAnswerTime))
    Steps.mediaRecorder.stop();

    Steps.localStream.getTracks().forEach(track => {
      Steps.localStream.removeTrack(track);
      track.stop();
    });
  }

  const doItAgain = () => {
    setHideTop(true);
    setRecordDone(false);
    setRecordPreseed(false);

    setCount(0);
    setResCount(0);
    setUploadState(false);
    setSendOnce(false)
  }

  const goNextPath = () => {
    setCount(0);
    setResCount(0);
    setUploadState(false);
    setSendOnce(false);
    history.push(nextPath);
  }

  function onMediaSuccess(stream) {
    var mediaRecorder = new MediaStreamRecorder(stream);
    // mediaRecorder.mimeType = 'video/webm;codecs="vp8,opus"';
    mediaRecorder.mimeType =  vcodec;
    mediaRecorder.videoWidth = 800;
    mediaRecorder.videoHeight = 480;
    Steps.mediaRecorder = mediaRecorder;
    Steps.localStream = stream;
    console.log('mimeType', vcodec)

    const recordId = nanoid(11);
    setRecordingId(recordId)

    var countBolb = 0;
    var fileName = '';
    var recordingInterval;
    clearInterval(recordingInterval);

    mediaRecorder.ondataavailable = function(blob) {

      countBolb = countBolb + 1;
      // console.log(blob);

      fileName = `${countBolb}.webm`;
    
      // create FormData
      var formData = new FormData();
      formData.append('section', `steps${id}`);
      formData.append('sessionId', sessionId);
      formData.append('userId', userId);
      formData.append('recordingId', recordId);
      formData.append('video', fileName);
      formData.append(`video-blob`, blob);

      // console.dir([...formData])
      uploadBlob(formData);
    };

    var timeInterval = 5000
    if (timeInterval) timeInterval = parseInt(timeInterval);
    else timeInterval = 2 * 1000;

    mediaRecorder.start(timeInterval);
  }

  function uploadBlob(formData) {

    var request = new XMLHttpRequest();
    setCount((prev) => prev + 1)

    request.onreadystatechange = function() {
      if (request.readyState === 4 && request.status === 200) {
        console.log('upload-ended');
        setResCount((prev) => prev + 1)
      }
    };

    request.upload.onerror = function(error) {
      console.log('Failed to upload to server');
      console.error('XMLHttpRequest failed', error);
    };
  
    request.upload.onabort = function(error) {
      console.log('Upload aborted.');
      console.error('XMLHttpRequest aborted', error);
    };
  
    request.open('POST', mediaPostUrl);
    request.send(formData);
  }

  function onMediaError(e) {
    console.error('media error', e);
  }

  // get strea
  if (notIOS) {
    GetStream(videoRef, status, hideTop);
  }

  // update state on uploading
  useEffect(() => {
    if ( count !== 0 && count === resCount && recordDone) {
      setUploadState(true);
     

      if ( recordDone) {
        var myFunc = function func() {
          setSendOnce(true)
          if ( sendOnce ) return;

          const formData = new FormData();
          formData.append('section', `steps${id}`);
          formData.append('sessionId', sessionId);
          formData.append('userId', userId);
          formData.append('recordingId', recordingId);
          formData.append('recordingDone', true);

          axios.post(mediaPostUrl, formData).then(res => {
            if (res.status === 200) {
              console.log(res.data)
              console.log('Recording Successfully Done!😂');
            } else {
              console.warn('something went wrong 😥');
            }
          })
        };
        myFunc()
      }
     
    } else {
      setUploadState(false)
    }
  }, [count, resCount, id, recordDone, sessionId, recordingId, sendOnce, userId, mediaPostUrl]);

  useEffect(() => {
    if (status === 'success' && isMounted.current) {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });

      if (codec === '2') {
        setVcodec('video/webm;codecs="vp9, opus"');
      } 
    }
  }, [isMounted, status, codec]);

  return (
    <>
      <Container>
        <Content heightCalc>
          {
            status === 'loading' ?
            <BlockSpinner /> : 
          
            <>
              <Heading align="center" mb={25}>{headline}</Heading>
              {hideTop && (
                <div>
                  <SubHeading align="center" mb={20}>{subheadline}</SubHeading>
                  {showHero}
                  <Para mb={30}>{text && generateParagraph(text)}</Para>

                  {notIOS && (
                    <div style={{ marginBottom: 20 }}>
                      <button onClick={() => setHideTop(!hideTop)} className={`sa-btn sa-btn-block`}>{toggleBtnText}</button>
                    </div>
                  )}
                </div>
              )}

              {isIOS && (
                <div>
                  {uploadPercent > 0 && (
                    <ProgressBox value={uploadPercent} mb={20} />
                  )}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <input
                      ref={register}
                      type="file"
                      name="userfile"
                      id="file"
                      accept="video/*"
                      capture="user"
                      style={{ opacity: 0, display: 'none'}}
                      onChange={(e) => onChangeFileName(e)}
                    />
                    <button type="submit" id="submit"  style={{ opacity: 0, display: 'none'}}>submit</button>
                  </form>
                  <div style={{ marginBottom: 20 }}>
                    <button
                      className={`sa-btn sa-btn-block ${disabledClass}`}
                      onClick={startRecord}
                    >
                    {nextBtnText}
                    </button>
                  </div>
                </div>
              )}

              {notIOS && !hideTop &&(
                <div className="ds">

                  {!recordDone && (
                    <div style={{ marginBottom: 20 }}>
                      <CenterMobile>
                        <CMElm>
                         
                          <TimeBox>
                            <TextCol>{remainingText}</TextCol>
                            <TimeCol>
                              <div className="minute-elm">{timerMinutes}</div>
                              <div className="titme-divider">&nbsp;:&nbsp;</div>
                              <div className="second-elm">{timerSeconds}</div>
                            </TimeCol>
                          </TimeBox>
                      
                          <div style={{ position: 'relative'}}>
                            {recordPreseed && ( 
                              <svg
                                width="38"
                                height="46"
                                viewBox="0 0 38 46"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  position: 'absolute',
                                  top: 20,
                                  right: 20,
                                  zIndex: 10,
                                }}
                              >
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.35522 10.5263C8.35522 5.17064 12.6969 0.828979 18.0526 0.828979C23.4083 0.828979 27.75 5.17064 27.75 10.5263C27.75 15.8821 23.4083 20.2237 18.0526 20.2237C12.6969 20.2237 8.35522 15.8821 8.35522 10.5263ZM3.19922 39.7949H5.5332L8.1992 45H11.3438V44.8633L8.2676 39.0527C9.1204 38.6686 9.7552 38.1544 10.1719 37.5098C10.5951 36.8587 10.8066 36.0482 10.8066 35.0781C10.8066 33.7109 10.3509 32.653 9.4395 31.9044C8.528 31.1557 7.2324 30.7812 5.55273 30.7812H0.269531V45H3.19922V39.7949ZM5.5625 37.422H3.19922V33.1544H5.55273C6.34049 33.1544 6.92318 33.3464 7.3008 33.7305C7.6849 34.1147 7.877 34.6419 7.877 35.3125C7.877 35.9701 7.6751 36.4876 7.2715 36.8652C6.86784 37.2364 6.29818 37.422 5.5625 37.422ZM22.5977 38.8379H16.9727V42.6465H23.5742V45H14.043V30.7812H23.5547V33.1544H16.9727V36.543H22.5977V38.8379ZM35.7559 43.877C36.778 42.998 37.3444 41.7936 37.4551 40.2637H34.5254C34.4603 41.1296 34.2031 41.7708 33.7539 42.1875C33.3112 42.5977 32.6374 42.8027 31.7324 42.8027C30.7298 42.8027 29.9941 42.4479 29.5254 41.7383C29.0632 41.0286 28.832 39.9056 28.832 38.3691V37.2949C28.8451 35.8236 29.0924 34.7396 29.5742 34.043C30.0625 33.3398 30.7949 32.9883 31.7715 32.9883C32.6699 32.9883 33.3372 33.1966 33.7734 33.6133C34.2161 34.0234 34.4733 34.6875 34.5449 35.6055H37.4746C37.3184 34.0169 36.7389 32.7832 35.7363 31.9043C34.7337 31.0254 33.4121 30.5859 31.7715 30.5859C30.5866 30.5859 29.5449 30.8659 28.6465 31.4258C27.7546 31.9792 27.0677 32.7767 26.5859 33.8184C26.1042 34.86 25.8633 36.0645 25.8633 37.4316V38.3008C25.8633 40.4427 26.3841 42.1289 27.4258 43.3594C28.474 44.5833 29.9095 45.1953 31.7324 45.1953C33.3991 45.1953 34.7402 44.7559 35.7559 43.877Z" fill="#E41A04"/>
                              </svg>
                            )}
                            <video
                              ref={videoRef}
                              muted
                              autoPlay
                              className="fn__video mobile-flip"
                              poster="https://staffapply.imgix.net/bg_loading.png?dpr=2&auto=compress,format&w=360"
                              style={{ width: '100%'}}
                            >
                            </video>
                          </div>
                        </CMElm>
                      </CenterMobile>
                    </div>
                  )}

                  {!recordPreseed && (
                    <div>
                      <div style={{ marginBottom: 25}}>
                        <button onClick={startRecording} className={`sa-btn sa-btn-block`}>{recordBtnText}</button>
                      </div>

                      <div style={{ marginBottom: 25}}>
                        <button
                          onClick={doItAgain}
                          className={`sa-btn sa-btn-block sa-btn-secondary`}
                        >
                          {backBTNText}
                        </button>
                      </div>
                    </div>
                  )}

                  {recordPreseed && !recordDone &&(
                    <div style={{ marginBottom: 25}}>
                      <button onClick={manualStop} className={`sa-btn sa-btn-block`}>{stopRecordBtnText}</button>
                    </div>
                  )}
                      
                  {recordDone && (
                    <div>

                      {(count - resCount) === 0 && (
                        <Para mb={30}>{text && generateParagraph(recordFinishedText)}</Para>
                      )}

                      {(count - resCount) !== 0 && (
                        <div style={{paddingBottom: 30 }}>
                          <Progress value={ Math.floor((100/count) * resCount)} bgSuccess height={20} />
                        </div> 
                      )}

                      <div style={{ paddingBottom: 30 }}>
                        <button
                          onClick={goNextPath}
                          className={`sa-btn sa-btn-block ${nextDisabled}`}
                        >
                          {nextStepBtnText}
                        </button>
                      </div>

                      <div style={{ paddingBottom: 30 }}>
                        <button
                          onClick={doItAgain}
                          className={`sa-btn sa-btn-block sa-btn-secondary ${nextDisabled}`}
                        >
                          {recordAgainBtnText}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}

              <Footer sectionName={`steps${id}`} />
            </>
          }
        </Content>
      </Container>
    </>
  )
}

export default Steps;

const TimeBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 18px;
  padding-bottom: 15px;
`;

const TextCol = styled.div`
  font-size: 16px;
  padding-right: 15px;
`;

const TimeCol = styled.div`
  display: flex;
  align-items: center;
`;
