import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { Container, Content } from '../containers/Container';

import theme from '../../styles/theme';
import brokenImage from '../../assets/broken_image.png';
import { useIsMounted } from '../../hooks/useIsMounted';

const Navbar = () => {
  const { settings, status } = useSelector(state => state.root);
  const [navSetting, setNavSetting] = useState(null);
  const isStateReady = (navSetting && navSetting.status === 200);

  const logoUrl = isStateReady ? navSetting.brand.image: brokenImage;
  const brandColor = isStateReady ? navSetting.brand.brandPrimaryColor : 'white';
  const brandBtnColor = isStateReady && navSetting.brand.brandBtnBgColor;
  const formBg = isStateReady ? navSetting.formBgColor : '#F1FAEE';

  let brandBgColor = isStateReady ? brandBtnColor: theme.primary;
  const brandTextColor = isStateReady ? navSetting.brand.brandBtnTextColor: 'white';

  if ( brandBgColor === null ) {
    brandBgColor = theme.primary;
  }

  const isMounted = useIsMounted();

  useEffect(() => {
    if (status === 'success' && isMounted.current) {
      setNavSetting(settings);
    }
  }, [isMounted, status, settings]);

  useEffect(() => {
    if (isMounted.current) {
      let root = document.documentElement;
      root.style.setProperty('--brand-primary', brandBgColor);
      root.style.setProperty('--brand-text', brandTextColor);
      root.style.setProperty('--form-bg', formBg);
      document.getElementById("theme_color").content = brandBgColor;
    }
  }, [isMounted, brandBgColor, brandTextColor, formBg]);

  return (
    <Header style={{ backgroundColor: brandColor }}>
      <Container>
        <Content>
          <nav>
            {navSetting && (
              <img className="sa__header__logo" src={logoUrl} alt="nav-logo" />
            )}
          </nav>
        </Content>
      </Container>
    </Header>
  )
}

export default Navbar;

const Header = styled.header.attrs({className: 'sa__header' })`
  box-shadow: 0px 3px 3px rgba(70, 69, 69, 0.1);
  background-color: white;

  nav {
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sa__header__logo {
    width: auto;
    max-height: 60px;
  }
`