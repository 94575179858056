import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { isIOS } from 'react-device-detect';

import axios from '../../services/api/axios';
import { Container, Content } from '../../components/containers/Container';
import Heading from '../../components/heading/Heading';
import SubHeading from '../../components/heading/SubHeading';
import Para from '../../components/para/Para';
import VideoIframe from '../../components/block/VideoIframe';
import HeroImage from '../../components/block/HeroImage';
import BlockSpinner from '../../components/spinner/BlockSpinner';
import ProgressBox from '../../components/progress/Progress';
import Footer from '../../components/footer/Footer';

import { useIsMounted } from '../../hooks/useIsMounted';
import { generateParagraph } from '../../utils/generateParagraph';
import { recordingSuccess, recordingFail } from '../../services/slices/testSlice';

const SampleQuestion = () => {
  // hooks
  const isMounted = useIsMounted();
  const history = useHistory();
  const dispatch = useDispatch();

  const { videoInterview, settings, status } = useSelector(state => state.root);
  const [screenState, setScreenState] = useState(null);
  const [uploadPercent, setUploadPercent] = useState(0);

  const sessionId = settings && settings.sessionId;
  
  const headline = screenState && screenState.sampleQuestion.headline;
  const subheadline = screenState && screenState.sampleQuestion.subheadline;
  const introText = screenState && screenState.sampleQuestion.text;
  const heroImage = screenState && screenState.sampleQuestion.heroImage.src;
  const heroVideo = screenState && screenState.sampleQuestion.video.src;
  const heroPriority = screenState && screenState.sampleQuestion.heroPriority;
  const postUrl = screenState && screenState.post.url;
  const RecordBtnText = screenState && screenState.sampleQuestion.btn.text;
  const nextPath = screenState && screenState.sampleQuestion.btn.path;

  const disabledClass =  uploadPercent > 0 ? 'disabled' : '';

  let showHero;

  if (heroPriority === 'video') {
    showHero = ( <VideoIframe title="welcome" src={heroVideo} mb={25} />)
  }

  if (heroPriority === 'image') {
    showHero = (<HeroImage src={heroImage} mb={10} alt="welcome hero"/>)
  }

  // useForm 
  const { register, handleSubmit,} = useForm();

  const userId = window.localStorage.getItem("userId");

  const startRecord = () => {
    document.getElementById('file').click();
  }
  
  // onChange file inputs
  const onChangeFileName = (e) => {
   document.getElementById('submit').click();
  }

  // form submit
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('section', 'sampleQuestion');
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('video', data.userfile[0]);

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor( (loaded * 100) / total);

        if ( percent < 100) {
          setUploadPercent(percent);
        }
      }
    }

    axios.post(postUrl, formData, options).then(res => {
      console.log('res :', res)
      if (res.status === 200) {
        setUploadPercent(0);
        dispatch(recordingSuccess({
          videoFile: res.data.videofile,
          videoType: res.data.type,
          videoPoster: res.data.videoPoster,
          section: res.data.section
        })
        );
        history.push({
          pathname: 'recording-success',
          state: { nextPath: nextPath }
      });
      } else {
        dispatch(recordingFail(res.error));
        window.location = `https://staffapply.com/video-error/?sid=${sessionId}&uid=${userId}`
      }
    })
  }
  
  useEffect(() => {
    if (status === 'success' && isMounted.current) {
      setScreenState(videoInterview && videoInterview);
    }
  }, [isMounted, status, videoInterview]);

  return (
    <>
      <Container>
        <Content heightCalc>
          {
            status === 'loading' ?
            <BlockSpinner /> : 
            <section className="screen__recording">

              <Heading
                align="center"
                mb={25}
              >
                {headline}
              </Heading>

              {showHero}

              <SubHeading align="center" mb={20}>{subheadline}</SubHeading>

              <Para mb={30}>{introText && generateParagraph(introText)}</Para> 

             {isIOS && (
              <div>
                {uploadPercent > 0 && (
                  <ProgressBox value={uploadPercent} mb={20} />
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <input
                    ref={register}
                    type="file"
                    name="userfile"
                    id="file"
                    accept="video/*"
                    capture="user"
                    style={{ opacity: 0, display: 'none'}}
                    onChange={(e) => onChangeFileName(e)}
                  />
                  <button type="submit" id="submit"  style={{ opacity: 0, display: 'none'}}>submit</button>
                </form>
                <div style={{ marginBottom: 20 }}>
                  <button
                    className={`sa-btn sa-btn-block ${disabledClass}`}
                    onClick={startRecord}
                  >
                    {RecordBtnText}
                  </button>
                </div>
              </div>
             )}

             <Footer sectionName="sample-question" />
            </section>
          }
        </Content>
      </Container>
    </>
  )
}

export default SampleQuestion
