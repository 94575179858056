import { createSlice } from '@reduxjs/toolkit';

const gdprAcknowledgmentSlice = createSlice({
  name: 'gdprAcknowledgment',
  initialState: {
    isLoading: false,
    isSuccess: false,
    error: '',
  }, 
  reducers: {
    acknowledgmentPending: (state) => {
      state.isLoading = true
    },
    acknowledgmentSuccess: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.error = '';
    },
    acknowledgmentFail: (state, { pyaload }) => {
      state.isLoading = false;
      state.error = pyaload;
    },
  }
});


export const { acknowledgmentPending, acknowledgmentSuccess, acknowledgmentFail } = gdprAcknowledgmentSlice.actions;


export default gdprAcknowledgmentSlice.reducer;