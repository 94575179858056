import React from 'react'
import styled from 'styled-components/macro';

const Checkbox = ({ label, checked,  ...props }) => {
  return (
    <Box>
      <Label>
        <InputWrap>
          <input type="checkbox" {...props} checked={checked} />
          <CheckMark className={`cbl-checkmark`} />
        </InputWrap>
        <Span>{label}</Span>
      </Label>
    </Box>
  )
}

export default Checkbox;

// styles
const Box = styled.div`
  position: relative;
  display: inline-block;
`

const Label = styled.label`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #434a50;
  line-height: 1;
  cursor: pointer;
`;

const CheckMark = styled.span`
  position: relative;
  display: block;
  height: 25px;
  width: 25px;
  border: 1px solid var(--muted);
  background-color: white;
  transition: all 150ms;

  &:after {
    display: none;
    content: "";
    position: absolute;
    left: 9px;
    top: 3px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  } 
`;

const InputWrap = styled.div`
  height: 25px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked + .cbl-checkmark {
      background-color: var(--muted);
      border-color: var(--muted);
    }

    &:checked ~ ${CheckMark}:after {
      display: block;
    }
  }
`

const Span = styled.span`
  color: inherit;
  font-weight: 400;
  font-size: 16px;
  padding-left: 10px;
`;
