import React from 'react';
import styled from 'styled-components/macro';

const BlurOverlay = ({ children }) => <Box><div>{children}</div></Box>
  
export default BlurOverlay;

const Box = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  z-index: 10;
  background-color: rgba(191, 191, 191, 0.4);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;

  > div {
    filter: none;
    width: 100%;
  }
`;
