// https://blog.logrocket.com/responsive-camera-component-react-hooks/
import { useState, useEffect } from "react";

export function useUserMedia(boolean) {
  const [mediaStream, setMediaStream] = useState(null);

  useEffect(() => {
    let constraints;
    const JSONconstraints = JSON.parse(window.localStorage.getItem("constraints"));

    if (JSONconstraints) {
      constraints = JSONconstraints;
    } else {
      constraints = { video: true, audio: true }
    }

    async function enableStream() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        setMediaStream(stream);
      } catch(err) {
        // Removed for brevity
      }
    }

    if (!mediaStream) {
      enableStream();
    } else {
      return function cleanup() {
        mediaStream.getTracks().forEach(track => {
          mediaStream.removeTrack(track);
          track.stop();
        });
      }
    }
  }, [mediaStream, boolean]);

  return mediaStream;
}


// import { useUserMedia } from './useUserMedia';
// example
// function Camera() {
//   const videoRef = useRef();
//   const mediaStream = useUserMedia(CAPTURE_OPTIONS);

//   if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
//     videoRef.current.srcObject = mediaStream;
//   }

//   function handleCanPlay() {
//     videoRef.current.play();
//   }

//   return (
//     <video ref={videoRef} onCanPlay={handleCanPlay} autoPlay playsInline muted />
//   );
// }