import { useEffect, useState} from 'react';

const GetStream = (ref, status, extra ) => {
  const [ele, setEle] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);
 
  useEffect(() => {
    const refCurrent = ref.current;

    let constraints;
    const JSONconstraints = JSON.parse(window.localStorage.getItem("constraints"));

    if (JSONconstraints) {
      constraints = JSONconstraints;
    } else {
      constraints = { video: true, audio: true }
    }

    async function enableStream() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        setMediaStream(stream);
        if (ele) {
          ele.srcObject = stream;
        }
      } catch(err) {
        console.log(err);
      }
    }

    if (extra) {
      setMediaStream(null);
    } 

    if (!mediaStream && refCurrent) {
      setEle(refCurrent);
      enableStream(); 
    } else {
      return function cleanup() {
        if(mediaStream) {
          mediaStream.getTracks().forEach(track => {
            mediaStream.removeTrack(track);
            track.stop();
          });
        }
      }
    }
  }, [status, ref, extra, mediaStream, ele]);
}

export default GetStream;