import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { useIsMounted } from '../../hooks/useIsMounted';

function Footer({ sectionName = 'home' }) {
  const isMounted = useIsMounted();

  const { settings, status } = useSelector(state => state.root);
  const [footerState, setFooterState] = useState(null);
  const copyright = footerState && footerState.copyright;
  const trackingUrl = footerState && footerState.trackingUrl;
  const sessionId = footerState && footerState.sessionId;
  const userId = window.localStorage.getItem("userId");

  useEffect(() => {
    if (status === 'success' && isMounted.current) {
      setFooterState(settings);
    }
  }, [isMounted, status, settings]);

  // console.log('Footer: ', `${trackingUrl}${sectionName}/${sessionId}/${userId}?${Date.now()}`)

  return status === 'success' ? (
    <>
      {copyright && (
        <Box>
          <div>
            <p>powered by</p>
            <img src="https://staffapply.imgix.net/powered-by-Logo-StaffApply.png?auto=compress,format&dpr=2&w=100" alt="staffapply" />
          </div>
        </Box>
      )}
      <img className="img-hidden" src={`${trackingUrl}${sectionName}/${sessionId}/${userId}?${Date.now()}`} alt="staffapply" />
    </>
  ) : null;
}

export default React.memo(Footer);

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 50px;
  margin-bottom: 30px;
 
  > div {
    padding-top: 15px;
    width: 100%;
    border-top: 1px solid #dedede;
  }

  p{
    font-size: 12px;
    color: var(--muted);
    margin-bottom: 10px;
  }

  img {
    max-width: 100px;
    height: auto;
  }
`;
