import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useHistory } from "react-router-dom";
import { isIOS } from 'react-device-detect';

import styled from 'styled-components/macro';
import {
  Container,
  Content,
  CenterMobile,
  CMElm
} from '../../components/containers/Container';

import { useIsMounted } from '../../hooks/useIsMounted';
import { generateParagraph } from '../../utils/generateParagraph';

const CameraActivation = () => {
  // hooks
  const videoRef = useRef(null);
  const isMounted = useIsMounted();
  const history = useHistory();
  const notIOS = !isIOS; 

  const [videoElement, setVideoElement] = useState(null);
  const [notAllowed, setNotAllowed] = useState('');
  const [info, setInfo] = useState('');
  const [notSupported, setNotSupported] = useState('');
  const [permission, setPermission] = useState(false);
  const [support, setSupport] = useState(false);
  
  const mediaAlert = JSON.parse(window.localStorage.getItem("mediaAlert"));
  
  function getDevices() {
    return navigator.mediaDevices.enumerateDevices();
  }

  const gotStream = useCallback(async (stream) => {
    window.stream = stream; 
    if (videoElement) {
      videoElement.srcObject = stream;
    }
    if (permission) {
      return
    } else {
      history.push('/media-recording-test');
    }
  }, [videoElement, permission, history]);

  const handleError = useCallback((error) => {
    if (error.name === 'NotAllowedError') {
      setPermission(true);
    } 
  }, []);

  const streamCallback = useCallback(async () => {
    if (window.stream) {
      window.stream.getTracks().forEach(track => {
        track.stop();
      });
    }
    return await navigator.mediaDevices.getUserMedia({ video: true, audio: true}).then(gotStream).catch(handleError);
  }, [gotStream, handleError])


  useEffect(() => {
    if (mediaAlert) {
      setNotAllowed(mediaAlert.notAllowed);
      setInfo(mediaAlert.info)
      setNotSupported(mediaAlert.notSupported)
    }
  }, [mediaAlert]); 

  useEffect(() => {
    if ( isMounted.current) {
      const refCurrent = videoRef.current;

      if (refCurrent) {
        setVideoElement(refCurrent)
      }

      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        setSupport(true);
        return;
      } else {
        streamCallback().then(getDevices);
      }
    }
  }, [isMounted, streamCallback, videoRef, history]);

  return (
    <>
      <Container>
        <Content heightCalc>
          <section className="screen__ac">
            {notIOS && (
              <div style={{ marginBottom: 25 }}>
                {info && (
                  <Info>
                    {info && generateParagraph(info)}
                  </Info>
                )}

                {permission && (
                  <Alert>
                    {notAllowed && generateParagraph(notAllowed)}
                  </Alert>
                )}
                
                {support && (
                  <Alert>
                    {notSupported && generateParagraph(notSupported)}
                  </Alert>
                )}
                
                <CenterMobile>
                  <CMElm>
                    <video
                      ref={videoRef}
                      muted
                      autoPlay
                      className="fn__video__01"
                    >
                    </video>
                  </CMElm>
                </CenterMobile>
              </div>
            )}
          </section>
        </Content>
      </Container>
    </>
  )
}

export default CameraActivation;

const Box = styled.div`
  text-align: center;
  padding: 1rem;

  p:last-child {
    margin-bottom: 0;
  }
`;

const Info = styled(Box)`   
  color: #084298;
  background-color: #cfe2ff;  
  margin-bottom: 1rem;         
`

const Alert = styled(Box)`
  color: #842029;
  background-color: #f8d7da;                
`