export const generateParagraph = (str) => {
  let result;

  function createMarkup(text) {
    return {__html: text};
  }

  if ((str.match(/\n/g)||[]).length) {
    result = str.split('\n').filter(item => item !== '').map((item, i) => <p key={i} dangerouslySetInnerHTML={createMarkup(item)} />)
  } else {
    result = <p dangerouslySetInnerHTML={createMarkup(str)} />
  }
  
  return result;
}