import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Content } from '../../components/containers/Container';
import Heading from '../../components/heading/Heading';
import SubHeading from '../../components/heading/SubHeading';
import Para from '../../components/para/Para';
import BlockSpinner from '../../components/spinner/BlockSpinner';
import HeroImage from '../../components/block/HeroImage';

import { useIsMounted } from '../../hooks/useIsMounted';
import { generateParagraph } from '../../utils/generateParagraph';

const VideoError = () => {
  const {errorVideoPage, status } = useSelector(state => state.root);
  const [screenState, setScreenState] = useState(null);

  
  const isMounted = useIsMounted();

  const headline = screenState && screenState.headline;
  const subheadline = screenState && screenState.subheadline;
  // const Isvisible = screenState && screenState.visible;
  const paragraphs = screenState && screenState.text;
  const heroImage = screenState && screenState.heroImage.src;
 
  useEffect(() => {
    if (status === 'success' && isMounted.current) {
      setScreenState(errorVideoPage);
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [isMounted, status, errorVideoPage]);

  return (
    <>
      <Container>
        <Content heightCalc>
          {
            status === 'loading' ?
            <BlockSpinner /> : 
            <section className="screen__video-error">
              <div style={{ height: 20 }} />
              <Heading
                align="center"
                mb={20}
              >
                {headline}
              </Heading>

              <HeroImage src={heroImage} mb={15} alt="home hero" />

              <SubHeading
                align="center"
                mb={20}
              >
                {subheadline}
              </SubHeading>

              <Para mb={20}>{paragraphs && generateParagraph(paragraphs)}</Para>
              <div style={{ height: 30 }} />
            </section>
          }
        </Content>
      </Container>
    </>
  )
}

export default VideoError
