import React from 'react';
import styled from 'styled-components/macro';

const VideoIframe = ({ title, src, mb, mt }) => {
  return (
    <Box
      mt={mt}
      mb={mb}
    >
      <div className="embed-responsive embed-responsive-16by9">
        <iframe
          title={title}
          src={src}
          allow="autoplay; fullscreen; picture-in-picture"
          >
        </iframe>
      </div>
    </Box>
  )
}

export default VideoIframe;

const Box = styled.div`
  ${({ mt }) => mt &&`
    margin-top: ${mt}px;
  `}

  ${({ mb }) => mb &&`
    margin-bottom: ${mb}px;
  `}
`;
