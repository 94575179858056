import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { Container, Content } from '../../components/containers/Container';
import BlockSpinner from '../../components/spinner/BlockSpinner';
import HeroImage from '../../components/block/HeroImage';
import Heading from '../../components/heading/Heading';
import SubHeading from '../../components/heading/SubHeading';
import Para from '../../components/para/Para';
import VideoIframe from '../../components/block/VideoIframe';
import WistiaEmbed from '../../components/WistiaEmbed';
import Footer from '../../components/footer/Footer';

import { changeLang } from '../../services/slices/rootSlice';
import { useIsMounted } from '../../hooks/useIsMounted';
import { generateParagraph } from '../../utils/generateParagraph';

const Home = () => {
  // hooks
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  
  
  const { home, settings, status } = useSelector(state => state.root);
  const sessionId = settings && settings.sessionId;
  const languages = settings && settings.languages;
  const defaultLang = settings && settings.languages.default;

  const [lang, setLang] = useState('');

  const nextBtnPath = home && home.btn.path;
  const nextBtnText = home && home.btn.text;
  const introText = home && home.text;
  const subheadline = home && home.subheadline;
  const heroImage = home && home.heroImage.src;
  const heroVideo = home && home.video.src;
  const heroPriority = home && home.heroPriority;
  const videoPlayer = home && home.video?.player;
  const nextPath =  nextBtnPath ? nextBtnPath : '/'

  const onChangeLanguage = (e) => {
    setLang(e.target.value);
    dispatch(changeLang(e.target.value));
  }

  let showHero;

  if (heroPriority === 'video') {
    if (videoPlayer === 'wistia') {
      showHero = (<div style={{ marginBottom: 25 }}><WistiaEmbed hashedId={heroVideo} /></div>)
    } else if (videoPlayer === 'Bunny') {
      showHero = (<VideoIframe title="welcome" src={heroVideo} mb={25} />)
    } else {
      showHero = (<VideoIframe title="welcome" src={heroVideo} mb={25} />)
    }
  }

  if (heroPriority === 'image') {
    showHero = (<HeroImage src={heroImage} mb={10} alt="welcome hero"/>)
  }

  useEffect(() => {
    if (status === 'success' && isMounted.current) {
      if (!sessionId) {
        window.location = `https://staffapply.com/not-found/`
      }
      setLang(defaultLang)
    }
   
  }, [isMounted, status, sessionId, defaultLang]);

  return (
    <>
      <Container>
        <Content heightCalc>
          {
            status === 'loading' ?
            <BlockSpinner /> : 
            <>
              {languages && languages.choice && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ minWidth: 127, marginBottom: 20 }}>
                  <select
                    className="custom-select"
                    name={lang}
                    defaultValue={lang}
                    onChange={onChangeLanguage}
                  >
                    {languages.langs && languages.langs.map(({ name, value }) => {
                      return <option key={name} value={value}>{name}</option>
                    })}
                  </select>
                </div>
              </div>
              )}

              <Heading mb={25} align="center">{home && home.headline}</Heading>

              {showHero}

              <SubHeading align="center" mb={20}>{subheadline}</SubHeading>
              
              <Para mb={30}>
                {introText && generateParagraph(introText)}
              </Para>

              <div style={{ paddingBottom: 25}}>
                <Link to={nextPath} className="sa-btn sa-btn-block">{nextBtnText}</Link>
              </div>
            
              <Footer sectionName="home" />
              
            </>
          }
        </Content>
      </Container>
    </>
  )
}

export default Home;