import { useEffect, useCallback } from 'react';
const ProcessStream = (ref, audioValue, videoValue, streamErrorCallBack, streamValue) => {

  const notSupport = !navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices;
  const currentRef = ref.current;

  function handleError(error) {
    console.error('Error: ', error);
  }

  const getStream = useCallback(async () => {

    const constraints = {
      audio: { 
        deviceId: audioValue ? {exact: audioValue} : undefined
      },
      video : {
        deviceId: videoValue ? {exact: videoValue} : undefined,
        width: { ideal: 1280},
        height: {  ideal: 720},
      }
    }
   
    window.localStorage.setItem('constraints', JSON.stringify(constraints));

    return navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      if (currentRef) {
        currentRef.srcObject = stream;
      }

      if (streamValue) {
        stream.getTracks().forEach(track => {
          stream.removeTrack(track);
          track.stop();
        });
      }
     
    }).catch(handleError);

  }, [audioValue, videoValue, currentRef, streamValue]);

 
  useEffect(() => {
    (async () => { 
      if (notSupport) {
        streamErrorCallBack();
        return;
      } else {
        // getStream().then(getDevices);
        getStream();
      }
    })();
  }, [notSupport, getStream, streamErrorCallBack]);

}

export default ProcessStream;