import React from 'react';
import styled from 'styled-components/macro';
import { BreakpointUpSmall } from '../../styles/Breakpoints';

const Heading = ({ children, align, mb, mt }) => {
  return (
    <Text
      align={align}
      mt={mt}
      mb={mb}
    >
      {children}
    </Text>
  )
}

export default Heading;

const Text = styled.h2`
  font-size: 20px;
  color: var(--dark);

  ${({ align }) => align &&`
    text-align: center;
  `}

  ${({ mt }) => mt &&`
    margin-top: ${mt}px;
  `}

  ${({ mb }) => mb &&`
    margin-bottom: ${mb}px;
  `}

  ${BreakpointUpSmall} {
    font-size: 23px;
  }
`;
