import React from 'react';
import styled from 'styled-components/macro';

const Para = ({ children, align, mb, mt }) => {
  return (
    <Box
      align={align}
      mt={mt}
      mb={mb}
    >
    {children}
    </Box>
  )
}

export default Para;

const Box = styled.div`
  font-size: 16px;

  ${({ align }) => align &&`
    text-align: center;
  `}

  ${({ mt }) => mt &&`
    margin-top: ${mt}px;
  `}

  ${({ mb }) => mb &&`
    margin-bottom: ${mb}px;
  `}

  p:empty {
    height: 0;
    margin: 0;
  }

  P:last-child {
    margin-bottom: 0;
  }
`;