import React, { useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { useSelector, useDispatch} from 'react-redux';

import { Container, Content } from '../../components/containers/Container';
import Heading from '../../components/heading/Heading';
import Para from '../../components/para/Para';
import Checkbox from '../../components/Checkbox/Checkbox';
import BlockSpinner from '../../components/spinner/BlockSpinner';
import Footer from '../../components/footer/Footer';

// space added 
import { useIsMounted } from '../../hooks/useIsMounted';
import { generateParagraph } from '../../utils/generateParagraph';

import {
  acknowledgmentPending,
  acknowledgmentSuccess,
  acknowledgmentFail 
} from '../../services/slices/gdprAcknowledgmentSlice';
import { sendGdprAcknowledgment } from '../../services/api/sendGdprAcknowledgment';

const Gdpr = () => {
  const dispatch = useDispatch();
  
  const {gdpr, settings, status } = useSelector(state => state.root);
  const [screenState, setScreenState] = useState(null);
  const [gdprBoolean, setGdprBoolean] = useState(false);
  
  const sessionId = settings && settings.sessionId;

  const headline = screenState && screenState.headline;
  const introText = screenState && screenState.text;
 
  const gdprLinkHref = screenState && screenState.link.href;
  const gdprLinkText = screenState && screenState.link.linkText;
  const postUrl = screenState && screenState.post.url;
  const nextBtnPath = screenState && screenState.btn.path;
  const nextBtnText = screenState && screenState.btn.text;

  const nextPath =  nextBtnPath ? nextBtnPath : '/'

  const accept = gdprLinkHref ? <a href={gdprLinkHref} target="_blank" rel="noreferrer">{gdprLinkText}</a> : gdprLinkText;
  const disabledClass = !gdprBoolean ? 'disabled' : '';

  const isMounted = useIsMounted();
  // const history = useHistory();

  const userId = window.localStorage.getItem("userId");

  const handleNext = async (e) => {
    dispatch(acknowledgmentPending());
    try { 
      const isSuccess = await sendGdprAcknowledgment(postUrl, { 
        section: 'gdpr',
        value: gdprBoolean,
        userId,
        session: sessionId
      });
     
      if (isSuccess.status === 'error') {
        return dispatch(acknowledgmentFail(isSuccess.message))
      }

      if (isSuccess.status === 200) {
        dispatch(acknowledgmentSuccess())
      }

    } catch (error) {
      dispatch(acknowledgmentFail(error.message));
    }
  }

  useEffect(() => {
    if (status === 'success' && isMounted.current) {
      setScreenState(gdpr && gdpr);
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [isMounted, status, gdpr]);

 
  return (
    <>
    <Container>
      <Content heightCalc>
        {
          status === 'loading' ?
          <BlockSpinner /> : 
          <>
            <Heading
              align="center"
              mt={20}
              mb={20}
            >
              {headline}
            </Heading>
            <Para mb={30}>{introText && generateParagraph(introText)}</Para>
            <Checkbox
              checked={gdprBoolean}
              onChange={(e) => setGdprBoolean(e.target.checked)}
              label={accept}
            />
            <div style={{ paddingTop: 20, marginBottom: 20 }}>
              <Link
                onClick={handleNext}
                to={nextPath}
                className={`sa-btn sa-btn-block ${disabledClass}`}
              >
                {nextBtnText}
              </Link>
            </div>
            <Footer sectionName="gdpr" />
          </>
        }
      </Content>
    </Container>
  </>
  )
}

export default Gdpr;